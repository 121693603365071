const getRoute = (args: {
  endpont: string;
  id?: string | number | undefined;
  rest?: string | number | undefined;
}) => {
  const apiMap: { [key: string]: string } = {
    mail_list: "/api/mailing-list/email",
    get_estates: "/api/estates",
    post_estates: "/api/estates/filter",
    post_lands: "/api/lands/filter",
    get_filter_stats_estate: "/api/estates/stats",
    get_filter_stats_land: "/api/lands/stats",
    get_estate: `/api/estates/${args.id}`,
    get_countries: `/api/countries`,
    get_blockchains: `/api/chains`,
    get_land: `/api/lands/${args.id}`,
  };
  return apiMap[args.endpont];
};

export default getRoute;
